<template>
    <div class="main-container">
        <div class="landing-page">
            <HeaderSection class="header-section" />
               <router-view></router-view>
            <FooterSection />
        </div>
        <Background v-if="turnOnBackground"/>
    </div>
</template>

<script>
import HeaderSection from "@/components/HeaderSection.vue";
import MakeMoneySection from "@/components/MakeMoneySection.vue";
import GetStartedSection from "@/components/GetStartedSection.vue";
import DepositSection from "@/components/DepositSection.vue";
import StakeBFeatureSection from "@/components/StakeBFeatureSection.vue";
import FooterSection from "@/components/FooterSection.vue";
import Background from "../components/Background.vue";
import HomePage from "./HomePage.vue"
export default {
    name: 'App',
    components: {
        HomePage,
        StakeBFeatureSection,
        HeaderSection,
        MakeMoneySection,
        DepositSection,
        GetStartedSection,
        FooterSection,
        Background,
    },
    data(){
        return{
            turnOnBackground: true
        }
    },
    created() {
        if (this.$route.path !=='/'){
            this.turnOnBackground = false;
        }
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400&family=Urbanist:ital,wght@0,500;0,700;1,500;1,600&display=swap');

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

* {
    scrollbar-width: thin;
    box-sizing: border-box;
}

::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    background: transparent;

}

.main-container {
    background: var(--back-ground-bg-default, #061025);
    position: relative;
}

.landing-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    z-index: 9999;
    position: relative;
}
</style>