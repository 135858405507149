<template>
    <div class="frame-184">
        <div class="frame-173">
            <div class="frame-141">
                <div class="frame-141-text">How to deposit and withdrawal</div>
                <div class="frame-183">
                    <div class="frame-183-text">
                        To deposit, choose an asset and follow instructions. Funds will be added after confirmation.
                    </div>
                    <div class="frame-183-text">
                        Follow the provided instructions to withdraw your desired digital asset. Please note that
                        withdrawal times may vary based on the chosen asset.
                    </div>
                </div>
            </div>
            <div class="group-a">
                <!-- <DepositIcon /> -->
                <img src="/img/Deposit.png" />
            </div>
        </div>
    </div>
</template>

<script>
import DepositIcon from "../assets/svg/deposit-icon.svg";

export default {
    name: "DepositSection",
    components: {
        DepositIcon
    }
}
</script>

<style scoped>
.frame-184 {
    display: flex;
    padding: 112px 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    max-width: 1200px;
    width: 100%;
}

.frame-173 {
    display: flex;
    width: 100%;
    padding: 32px;
    align-items: flex-start;
    gap: 48px;
    border-radius: 16px;
    background: linear-gradient(125deg, rgba(219, 230, 222, 0.1) 0%, #F9B721 100%);
    position: relative;
}

.frame-141 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
}

.frame-141-text {
    color: var(--color-text-primary, #F1F5F9);
    font-family: Urbanist;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    /* 100% */
}

.frame-183 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    max-width: 682px;
}

.frame-183-text {
    align-self: stretch;
    color: var(--color-text-read, #E2E8F0);
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 140% */
}

.group-a {
    width: 409.66px;
    height: 345.215px;
    position: absolute;
    right: -29.66px;
    top: -19px;
}

@media screen and (max-width: 1440px) {
    .frame-184 {
        padding: 112px 16px;
    }
}

@media screen and (max-width: 768px) {
    .frame-173 {
        flex-direction: column;
        padding: 30px 16px;
    }

    .group-a {
        position: inherit;
        margin: auto;
        top: 0;
        left: 0;
        height: auto;
        width: auto;
    }

    .frame-184 {
        padding: 40px 16px;
    }
}
</style>