<template>
    <div class="frame-190">
        <div class="frame-142">
            <div class="frame-138">
                <div class="frame-138-text-1">StakeB features</div>
                <div class="frame-138-text-2">
                    StakeB is a user-friendly platform that simplifies investing in digital assets
                    by providing a range of accessible features and support for multiple digital assets.
                </div>
                <div class="frame-138-text-2">
                    Users can earn rewards by mining digital assets for free and referring friends to StakeB's affiliate
                    program.
                </div>
            </div>
            <div class="frame-140">
                <div class="frame-174">
                    <div class="card-features-1">
                        <div class="feature-icon">
                            <img src="/img/feature1.png" />
                        </div>
                        <div class="frame-139">
                            <div class="frame-139-text-1">Simplicity</div>
                            <div class="frame-139-text-2">Trading is literally just a click away.</div>
                        </div>
                    </div>
                    <div class="card-features-2">
                        <div class="feature-icon">
                            <img src="/img/feature2.png" />
                        </div>
                        <div class="frame-139">
                            <div class="frame-139-text-1">Multiple assets</div>
                            <div class="frame-139-text-2">Investors can select from a wide range of assets to trade. </div>
                        </div>
                    </div>
                </div>
                <div class="frame-174">
                    <div class="card-features-1">
                        <div class="feature-icon">
                            <img src="/img/feature3.png" />
                        </div>
                        <div class="frame-139">
                            <div class="frame-139-text-1">Free mining</div>
                            <div class="frame-139-text-2">After a certain period of time, you will receive a BTC rewards.
                            </div>
                        </div>
                    </div>
                    <div class="card-features-2">
                        <div class="feature-icon">
                            <img src="/img/feature4.png" />
                        </div>
                        <div class="frame-139">
                            <div class="frame-139-text-1">Affiliate</div>
                            <div class="frame-139-text-2">Share your friends to get more rewards and more profitable.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ArrowRightIcon from "../assets/svg/arrow-right.svg";
import SimplicityIcon from "../assets/svg/simplicity-icon.svg";
import MultipleAssetsIcon from "../assets/svg/multiple-asset-icon.svg";
import FreeMiningIcon from "../assets/svg/free-minining.svg";
import AffiliateIcon from "../assets/svg/affiliate-icon.svg";

export default {
    name: "StakeBFeature",
    data() {
        return {
            getStartedURL: process.env.VUE_APP_USER_FRONTEND_URL
        }
    },
    components: {
        ArrowRightIcon, SimplicityIcon, MultipleAssetsIcon, FreeMiningIcon, AffiliateIcon
    },
    data() {
        return {
            signInURL: process.env.VUE_APP_AUTHENTICATION_URL,
        }
    }
}

</script>

<style scoped>
.frame-190 {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 112px 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-top: 1px solid var(--back-ground-bg-secondary, #1E293B);
}

.frame-142 {
    display: flex;
    max-width: 1200px;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
}

.frame-138 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    flex: 1 0 0;
}

.frame-138-text-1 {
    color: var(--color-text-primary, #F1F5F9);
    font-family: Urbanist;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    /* 100% */
    align-self: stretch;
}

.frame-138-text-2 {
    color: var(--color-text-secondary, #94A3B8);
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 140% */
}

.frame-138-btn {
    display: flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--color-text-brand, #F9BC21);
    text-decoration: none;
}

.btn-playing-demo {
    color: #000;
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    text-decoration: none;
}

.frame-140 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
    margin: 0 auto;
}

.frame-174 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
}

.card-features-1 {
    display: flex;
    padding: 32px;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 20px;
    border: 1px solid var(--back-ground-bg-divider, #475569);
    background: var(--back-ground-bg-default, #0F172A);
}

.feature-icon {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 20px;
}

.frame-139 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}

.frame-139-text-1 {
    align-self: stretch;
    color: var(--color-text-primary, #F1F5F9);
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 133.333% */
}

.frame-139-text-2 {
    color: var(--color-text-read, #E2E8F0);
    /* lead-18 */
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 155.556% */
    align-self: stretch;
}

.card-features-2 {
    display: flex;
    padding: 32px;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 20px;
    border: 1px solid var(--back-ground-bg-divider, #475569);
    background: var(--back-ground-bg-secondary, #1E293B);
}

@media screen and (max-width: 1440px) {
    .frame-190 {
        padding: 112px 16px;
    }
}

@media screen and (max-width: 768px) {
    .frame-142 {
        flex-direction: column;
    }

    .frame-190 {
        padding: 40px 16px;
    }

    .feature-icon img {
        width: 56px;
        height: 56px;
    }
}
</style>