import Vue from 'vue'
import App from './App.vue'
import Toast,{ POSITION }from "vue-toastification";
import '@fortawesome/fontawesome-free/css/all.css';
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
const options = {
    // You can set your default options here
    position: POSITION.TOP_RIGHT,
    timeout: 2000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false
};
Vue.use(Toast, options);
Vue.config.productionTip = false
import router from './router'
new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
