<template>
    <div class="frame-8">
        <div class="frame-189" style="z-index: 1;">
            <div class="frame-178">
                <div class="frame-137">
                    <div class="frame-137-text-1">To make more money?</div>
                    <div class="frame-137-text-2">Please click tap on miner, you will get a part of that we have
                        mining.
                    </div>
                </div>

            </div>
            <div class="image-miner">
                <div class="group-7">
                    <div class="miner-section">
                        <div style="position: relative">
                            <img :src="miner_image" class="miner-image" :class="{
                                'miner-image-running': running,
                                'click-here': !click_here_required,
                            }" @click="mine" />
                            <div class="gold-heap-3">
                                <GoldHeap3 />
                            </div>
                        </div>
                        <div class="btn-click-here" v-if="!click_here_required" @click="clickHere">
                            <div class="frame-178-btn-text">Click Here!</div>
                        </div>
                    </div>
                    <div class="gold-heap-1">
                        <GoldHeap1 />
                    </div>
                    <div class="gold-heap-2">
                        <GoldHeap2 />
                    </div>
                </div>
            </div>
        </div>

        <Congratulation :is-show="this.phrase >= this.num_of_phrase + 2" :close-prize="closePrize" />
    </div>
</template>

<script>
import GoldHeap1 from "../assets/svg/gold-heap-1.svg";
import GoldHeap2 from "../assets/svg/gold-heap-2.svg";
import GoldHeap3 from "../assets/svg/gold-heap-3.svg";
import CloseIcon from "@/assets/svg/close-icon.svg";
import BitcoinIcon from "@/assets/svg/bitcoin-icon.svg";
import Congratulation from "@/components/Congratulation.vue";

export default {
    name: "MakeMoneySection",
    components: {
        BitcoinIcon, CloseIcon,
        GoldHeap1,
        GoldHeap2,
        GoldHeap3,
        Congratulation
    },
    data() {
        return {
            miner_image: "/img/mining/1.gif",
            phrase: 1,
            num_of_phrase: 2,
            running: false,
            prize: 10000,
            preload_images: [],
            click_here_required: false,
            signInURL: process.env.VUE_APP_AUTHENTICATION_URL,
        }
    },
    created() {
        [
            "/img/mining/animation_1.gif",
            "/img/mining/animation_2.gif",
            "/img/mining/animation_3.gif",
            "/img/mining/1.gif",
            "/img/mining/2.gif",
            "/img/mining/3.gif",
            "/img/mining/4.gif",
            "/img/mining/escape.png",
        ].forEach((url) => {
            const image = new Image(717, 420);
            image.src = url;
            this.preload_images.push(image);
        });
    },
    methods: {
        mine(evt) {
            evt.preventDefault();
            if (this.phrase === this.num_of_phrase + 1 || this.running) {
                return;
            }
            this.phrase++;
            switch (this.phrase) {
                case 2: {
                    this.running = true;
                    this.miner_image = "/img/mining/animation_1.gif";
                    setTimeout(() => {
                        this.miner_image = "/img/mining/2.gif";
                        this.running = false;
                    }, 1520)
                    break;
                }
                case (this.phrase > 2 && this.phrase < this.num_of_phrase): {
                    this.running = true;
                    this.miner_image = "/img/mining/animation_2.gif";
                    setTimeout(() => {
                        this.miner_image = "/img/mining/2.gif";
                        this.running = false;
                    }, 1280);
                    break;
                }
                case this.num_of_phrase: {
                    this.running = true;
                    this.miner_image = "/img/mining/animation_2.gif";
                    setTimeout(() => {
                        this.miner_image = "/img/mining/3.gif";
                        this.running = false;
                    }, 1280);
                    break;
                }
                case this.num_of_phrase + 1: {
                    this.running = true;
                    this.miner_image = "/img/mining/animation_3.gif";
                    setTimeout(async () => {
                        this.miner_image = "/img/mining/4.gif";
                        this.running = false;
                        this.phrase++;
                        setTimeout(() => {
                            this.phrase++;
                        }, 500);
                    }, 3300)
                }

            }
        },
        closePrize(evt) {
            evt.preventDefault();
            // this.phrase++;
            // setTimeout(() => {
            this.phrase = 1;
            this.num_of_phrase = 2 + Math.round(Math.random() * 3);
            this.miner_image = "/img/mining/1.gif";
            this.click_here_required = false
            // }, 500);
        },
        clickHere(evt) {
            this.click_here_required = true;
        },
    },
    beforeDestroy() {
        clearInterval(this.interval);
    },

}
</script>

<style scoped>
.frame-8 {
    display: flex;
    padding: 48px 0 112px 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    max-width: 1200px;
    width: 100%;
}

.frame-189 {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    width: 100%;
}

.image-miner {
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;

}

.group-7 {
    width: 100%;
    flex-shrink: 0;
    position: relative;
    height: 672px;
    justify-content: center;
    overflow: hidden;
}

.frame-178 {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    align-self: stretch;
}

.frame-137 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.frame-137-text-1 {
    color: var(--color-text-primary, #F1F5F9);
    text-align: center;
    font-family: Urbanist;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    /* 100% */
    align-self: stretch;
}

.frame-137-text-2 {
    color: var(--color-text-read, #E2E8F0);
    text-align: center;
    /* lead-20 */
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 140% */
    align-self: stretch;
}

.btn-click-here {
    display: flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--color-text-brand, #F9BC21);
    top: 52px;
    position: absolute;
    cursor: pointer;
}

.frame-178-btn-text {
    color: #000;
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 155.556% */
}

.miner-section {
    display: flex;
    justify-content: center;
}

.miner-image {
    display: flex;
    position: relative;
    max-width: 717px;
    max-height: 420px;
    z-index: 1;
    cursor: pointer;
}

.gold-heap-1 {
    display: flex;
    position: absolute;
    max-width: 803px;
    max-height: 101.068px;
    flex-shrink: 0;
    top: 460px;
    left: 32px;
}

.gold-heap-2 {
    display: flex;
    position: absolute;
    max-width: 531px;
    max-height: 54.094px;
    flex-shrink: 0;
    top: 618.61px;
    right: 89.22px;
}

.gold-heap-3 {
    position: absolute;
    bottom: 8.98px;
    right: 180px;
}

.click-here {
    opacity: 0.3;
    pointer-events: none;
}
</style>
