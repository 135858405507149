<template>
    <div class="privacy-policy">
        <div class="container">
            <div class="frame-title">
                <div class="title">Privacy Policy</div>
            </div>
            <div class="frame-content">
                <div class="wrapper-text">
                    <p>At StakeB, we are committed to protecting your privacy and ensuring
                        the security of your personal information. This Privacy Policy outlines how we collect, use, and
                        protect the information you provide to us when using our services.
                    </p>
                </div>
                <div class="title-1">1. Information We Collect</div>
                <div class="wrapper-text">
                    <p>We may collect the following types of information: </p>
                    <ul>
                        <li>Personal Information: This includes your name, email address, phone number, and other
                            contact details that you provide when registering an account with us.
                        </li>
                        <li>Financial Information: We may collect information related to your financial transactions,
                            including bank account details, credit card information, and trading activity.
                        </li>
                        <li>Technical Information: We may collect information about your device, IP address, browser
                            type, and operating system to improve our services and ensure security.
                        </li>
                    </ul>
                </div>
                <div class="title-1">2. How We Use Your Information</div>
                <div class="wrapper-text">
                    <p>We use the collected information for the following purposes: </p>
                    <ul>
                        <li>Account Management: To create and manage your trading account, process transactions, and
                            provide customer support.
                        </li>
                        <li>Improve Services: To enhance our trading platform, develop new features, and personalize
                            your user experience.
                        </li>
                        <li>Compliance: To comply with legal and regulatory requirements, including anti-money
                            laundering (AML) and know your customer (KYC) obligations.
                        </li>
                        <li>Marketing: With your consent, we may send you promotional offers, newsletters, and updates
                            about our products and services.
                        </li>
                    </ul>
                </div>
                <div class="title-1">3. Information Sharing</div>
                <div class="wrapper-text">
                    <p>We may share your personal information with third-party service providers, business partners, or
                        regulatory bodies, only to the extent necessary for the operation and enhancement of our
                        services.
                        We ensure that any third parties we work with are obligated to protect your information in
                        accordance with applicable laws and industry standards. </p>
                </div>
                <div class="title-1">4. Data Security</div>
                <div class="wrapper-text">
                    <p>We implement appropriate security measures to protect your personal information from unauthorized
                        access, alteration, disclosure, and destruction. However, please note that no method of
                        transmission
                        over the internet or electronic storage is entirely secure, and we cannot guarantee absolute
                        security.
                    </p>
                </div>
                <div class="title-1">5. Cookies and Tracking Technologies</div>
                <div class="wrapper-text">
                    <p>
                        We may use cookies and other tracking technologies to enhance the user experience on our
                        platform.
                        These technologies help us recognize your preferences, personalize content and ads, and
                        understand
                        how you interact with our website.
                    </p>
                </div>
                <div class="title-1">6. Third-Party Websites</div>
                <div class="wrapper-text">
                    <p>Our platform may contain links to third-party websites. We do not have control over these
                        websites
                        and are not responsible for their privacy practices. We recommend reviewing the privacy policies
                        of
                        any third-party websites you visit.
                    </p>
                </div>
                <div class="title-1">7. Changes to this Privacy Policy</div>
                <div class="wrapper-text">
                    <p>
                        We reserve the right to update or modify this privacy policy at any time. Any changes will be
                        effective immediately upon posting on our website. We encourage you to review this policy
                        periodically for any updates.
                    </p>
                </div>
                <div class="title-1">8. Your acceptance of these terms</div>
                <div class="wrapper-text">
                    <p>
                        By using this Site, you signify your acceptance of this policy. If you do not agree to this
                        policy,
                        please do not use our Site. Your continued use of the Site following the posting of changes to
                        this
                        policy will be deemed your acceptance of those changes.
                    </p>
                </div>

                <div class="title-1">9. Contact Us</div>
                <div class="wrapper-text">
                    <p>
                        If you have any questions, concerns, or requests regarding your personal information or this
                        privacy
                        policy, please contact us at <a
                            href="mailto:stakeb.helpcenter@gmail.com"><span>stakeb.helpcenter@gmail.com</span></a>.
                    </p>

                </div>
                <div class="wrapper-text">Effective October 1, 2023</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "TermOfService"
}
</script>

<style scoped>
.privacy-policy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    max-width: 1200px;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 30px;
    font-family: Urbanist;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.frame-title {
    display: flex;
    max-width: 789px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.title {
    color: var(--color-text-primary, #F1F5F9);
    text-align: center;
    font-family: Urbanist;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    align-self: stretch;
}

.effective-date {
    color: var(--color-text-read, #E2E8F0);
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 140% */
}

.frame-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

}

.wrapper-text {
    align-self: stretch;
    color: var(--color-text-read, #E2E8F0);
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.title-1 {
    color: var(--color-text-primary, #F1F5F9);
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 133.333% */
    align-self: stretch;
}


@media screen and (max-width: 1440px) {
    .privacy-policy {
        padding: 0 16px;
        margin-top: 20px;
    }

    .title {
        font-size: 30px;
    }

    .title-1 {
        font-size: 20px;
    }

    .wrapper-text {
        font-size: 16px;
    }
}
</style>