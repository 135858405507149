<template>
    <div class="term-of-service">
        <div class="container">
            <div class="frame-title">
                <div class="title">Term of Service</div>
            </div>
            <div class="frame-content">
                <div class="wrapper-text">
                    <p>
                        Please familiarise yourself with the following Terms and service before using
                        stakeB.live.
                    </p>
                    <p>
                        By using the website you are agreeing to be bound by these terms and conditions. If you register
                        as a member of the website, we will ask you to give your express agreement to these terms and
                        conditions. You must be at least 18 years of age to use our website.
                    </p>
                </div>
                <div class="title-1">1. Eligibility</div>
                <div class="wrapper-text">
                    <p>
                        By using our services, you warrant that you are over
                        the legal age required in your jurisdiction and have the legal capacity to enter into a binding
                        agreement.
                    </p>
                </div>
                <div class="title-1">2. Risk Disclosure</div>
                <div class="wrapper-text">
                    <p>
                        You acknowledge and understand that investment digital asset involves a high level of
                        risk. You agree to assume full responsibility for any financial losses incurred while using our
                        services and understand that past performance is not indicative of future results.
                    </p>
                </div>
                <div class="title-1">3. Compliance with Laws</div>
                <div class="wrapper-text">
                    <p>
                        You agree to comply with all applicable laws and regulations regarding the use of our services.
                        It is your responsibility to ensure that you are legally allowed to access and participate in
                        these activities in your jurisdiction.
                    </p>
                </div>
                <div class="title-1">4. Account Registration</div>
                <div class="wrapper-text">
                    <p>
                        To use our services, you will need to create an account. You must provide accurate and complete
                        information during the registration process. You are responsible for maintaining the
                        confidentiality of your account credentials and agree to notify us immediately of any
                        unauthorized use of your account.
                    </p>
                </div>
                <div class="title-1">5. Trading Limits</div>
                <div class="wrapper-text">
                    <p>
                        We reserve the right to set certain trading limits, including maximum trade amounts and leverage
                        levels. These limits may vary depending on your user level, account verification status, and
                        market conditions.
                    </p>
                </div>
                <div class="title-1">6. Deposits and Withdrawals</div>
                <div class="wrapper-text">
                    <p>
                        You may deposit funds into your account through approved payment methods. All deposits and
                        withdrawals must comply with our anti-money laundering policies and may be subject to
                        verification procedures. We reserve the right to request additional documentation for
                        verification purposes.
                    </p>
                </div>
                <div class="title-1">7. Intellectual Property</div>
                <div class="wrapper-text">
                    <p>
                        All content, trademarks, logos, and materials provided through our services are the property of
                        the respective owners and are protected by
                        intellectual property laws. You agree not to reproduce, distribute, or modify any of these
                        materials without obtaining proper authorization.
                    </p>
                </div>
                <div class="title-1">8. Termination</div>
                <div class="wrapper-text">
                    <p>
                        We reserve the right to suspend, restrict, or terminate your access to our services at any time
                        and for any reason. This may include but is not limited to violations of these Terms of Service
                        or any applicable laws.
                    </p>
                </div>
                <div class="title-1">9. Disclaimer of Liability</div>
                <div class="wrapper-text">
                    <p>
                        We provide our services on an "as is" and "as
                        available" basis. We do not guarantee the accuracy, timeliness, completeness, or reliability of
                        any information or services provided. You agree that we shall not be liable for any direct or
                        indirect losses, damages, or expenses arising from your use of our services.

                    </p>
                </div>
                <div class="title-1">10. Amendments</div>
                <div class="wrapper-text">
                    <p>
                        We reserve the right to modify or update these Terms of Service at any time without prior
                        notice. It is your responsibility to review the most current version of the terms. Continued use
                        of our services after any modifications constitutes your acceptance of the updated terms.
                    </p>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TermOfServices"
}
</script>

<style scoped>
.term-of-service {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    max-width: 1200px;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 30px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.frame-title {
    display: flex;
    max-width: 789px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.title {
    color: var(--color-text-primary, #F1F5F9);
    text-align: center;
    font-family: Urbanist;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    align-self: stretch;
}

.effective-date {
    color: var(--color-text-read, #E2E8F0);
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 140% */
}

.frame-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

}

.wrapper-text {
    align-self: stretch;
    color: var(--color-text-read, #E2E8F0);
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.title-1 {
    color: var(--color-text-primary, #F1F5F9);
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 133.333% */
    align-self: stretch;
}

@media screen and (max-width: 1440px) {
    .term-of-service {
        padding: 0 16px;
        margin-top: 20px;
    }

    .title {
        font-size: 30px;
    }

    .title-1 {
        font-size: 20px;
    }

    .wrapper-text {
        font-size: 16px;
    }
}
</style>