import Api from './Api'

export default {
    auth() {
        return Api().get('auth');
    },
    createAccount(params) {
        return Api().post('user/login_telegram', params)
    },
    signUp(params) {
        return Api().post('user/sign_up', params)
    },
    forget(params) {
        return Api().post('user/reset_password', params)
    },
    twoFAVerify(params) {
        return Api().post('user/two_fa_verify', params)
    },
}