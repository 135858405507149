<template>
<div class="how-to-start-trading">
    <div class="container">
        <div class="frame-title">
            <div class="title">How To Start Trading?</div>
        </div>
        <div class="frame-content">
            <div class="title-1">Prerequisites</div>
            <div class="wrapper-text">
                <ul>
                    <li> Your <b>Main Wallet</b> has a minimum amount is <b>0.1</b> USDT.</li>
                </ul>
            </div>
            <div class="title-1">Step 1: Transfer token to the Trading Wallet </div>
            <div class="wrapper-text">
                <ul>
                    <li>In the <b>Trading Wallet </b>, we exclusively accept USDT tokens.</li>
                    <li>If you have other tokens, please swap them to USDT before transferring them to the <b>Trading Wallet </b>. On our platform, we support token swaps with an affordable 2% fee per transaction. </li>
                    <li>In token type USDT, click on the <b>Operation </b> button and select <b>Transfer</b>. A pop-up window will appear with transfer information.</li>
                </ul>
            </div>
            <div class="title-1">Step 2: Input amount token you want to transfer </div>
            <div class="wrapper-text">
                <ul>
                    <li>In the pop-up window, please enter the amount you wish to transfer from your <b>Main Wallet </b> to the <b>Trading Wallet</b>.</li>
                    <li>Ensure a successful transfer by checking your <b>Real Account </b> balance.</li>
                </ul>
            </div>
            <div class="title-1">Step 3: Switch to the Real account to start trading </div>
            <div class="wrapper-text">
                <ul>
                    <li>When every process transfer is done, you can start trading by switching accounts from the <b>Demo Account</b> to the <b>Real Account</b>.</li>
                </ul>
            </div>
            <div class="title-1">Optional: Transfer from the Trading Wallet to the Main Wallet </div>
            <div class="wrapper-text">
                <ul>
                    <li>In the <b>Trading Wallet</b>, click on the <b>Operation </b> button and select <b>Transfer</b>. A pop-up window will appear with transfer information.</li>
                    <li>Choose to right side you want to transfer. Do an action transfer and check your <b>Main Wallet</b> balance.</li>
                </ul>
            </div>
        </div>

    </div>
</div>
</template>

<script>
export default {
    name: "HowToStartTrading"
}
</script>

<style scoped>
.how-to-start-trading{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    max-width: 1200px;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 30px;
    font-family: Urbanist;
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.frame-title {
    display: flex;
    max-width: 789px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}
.title {
    color: var(--color-text-primary, #F1F5F9);
    text-align: center;
    font-family: Urbanist;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    align-self: stretch;
}
.frame-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    min-height: 60vh;
}
.wrapper-text {
    align-self: stretch;
    color: var(--color-text-read, #E2E8F0);
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}
.title-1 {
    color: var(--color-text-primary, #F1F5F9);
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 133.333% */
    align-self: stretch;
}


@media screen and (max-width: 1440px) {
    .how-to-start-trading {
        padding: 0 16px;
        margin-top: 20px;
    }

    .title {
        font-size: 30px;
    }

    .title-1 {
        font-size: 20px;
    }

    .wrapper-text {
        font-size: 16px;
    }
}
</style>