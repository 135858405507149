<template>
    <div class="frame-187">
        <div class="frame-172">
            <div class="frame-144">
                <div class="frame-144-text-1">Open an account in 3 simple steps</div>
                <div class="frame-144-text-2">Easy and convenient deposit and withdrawal methods</div>
            </div>
            <div class="frame-145">
                <div class="card-features">
                    <div class="frame-169">
                        <RegisterIcon class="icon-feature"></RegisterIcon>
                        <div class="ellipse-4"></div>
                    </div>
                    <div class="frame-139">
                        <div class="frame-139-text-1">Register</div>
                        <div class="frame-139-text-2">It will take less than 15 seconds of your time.</div>
                    </div>
                </div>
                <div class="frame-170">
                    <ChevronsRight />
                </div>
                <div class="card-features">
                    <div class="frame-169">
                        <FundIcon class="icon-feature"></FundIcon>
                        <div class="ellipse-4"></div>
                    </div>
                    <div class="frame-139">
                        <div class="frame-139-text-1">Fund</div>
                        <div class="frame-139-text-2">Fund your account trading using deposit methods..</div>
                    </div>
                </div>
                <div class="frame-170">
                    <ChevronsRight />
                </div>
                <div class="card-features">
                    <div class="frame-169">
                        <TradeIcon class="icon-feature"></TradeIcon>
                        <div class="ellipse-4"></div>
                    </div>
                    <div class="frame-139">
                        <div class="frame-139-text-1">Trade</div>
                        <div class="frame-139-text-2">Start trading on your real account..</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RegisterIcon from "../assets/svg/register-icon.svg";
import ChevronsRight from "../assets/svg/chevrons-right.svg";
import FundIcon from "../assets/svg/fund-step-icon.svg";
import TradeIcon from "../assets/svg/trade-icon.svg";
import ArrowRightIcon from "../assets/svg/arrow-right.svg";

export default {
    name: "GetStarted",
    components: {
        RegisterIcon,
        ChevronsRight,
        FundIcon,
        TradeIcon,
        ArrowRightIcon
    },
    data() {
        return {
            signInURL: process.env.VUE_APP_AUTHENTICATION_URL,
        }
    }
}
</script>

<style scoped>
.frame-187 {
    display: flex;
    padding: 112px 0;
    width: 100%;
    max-width: 1200px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.frame-172 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin: 0 auto;
}

.frame-144 {
    display: flex;
    max-width: 789px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.frame-144-text-1 {
    color: var(--color-text-primary, #F1F5F9);
    text-align: center;
    font-family: Urbanist;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    /* 100% */
    align-self: stretch;
}

.frame-144-text-2 {
    color: var(--color-text-secondary, #94A3B8);
    text-align: center;
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 140% */
    align-self: stretch;
}

.frame-145 {
    display: flex;
    position: relative;
    max-width: 1200px;
    align-items: flex-start;
}

.card-features {
    display: flex;
    padding: 24px;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid var(--back-ground-bg-divider, #475569);
    background: var(--back-ground-bg-default, #0F172A);
}

.card-features:hover {
    border-radius: 16px;
    border: 1px solid var(--back-ground-bg-accent, #F9BC21);
    background: var(--back-ground-bg-default, #0F172A);
}

.frame-169 {
    display: flex;
    padding: 16px;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 8px;
    border-radius: 80px;
}

.frame-139 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.frame-139-text-1 {
    color: var(--color-text-primary, #F1F5F9);

    /* h3 */
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 133.333% */
    align-self: stretch;
}

.frame-139-text-2 {
    color: var(--color-text-read, #E2E8F0);
    align-self: stretch;
    /* lead-18 */
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 155.556% */
}

.frame-169 {
    background: var(--back-ground-bg-amber-op, rgba(249, 188, 33, 0.12));
    border-radius: 80px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: flex-end;
    justify-content: flex-end;
    flex-shrink: 0;
    position: relative;
}

.icon-feature {
    flex-shrink: 0;
    position: relative;
    overflow: visible;
}

.ellipse-4 {
    background: var(--back-ground-bg-accent, #f9bc21);
    border-radius: 50%;
    flex-shrink: 0;
    width: 34px;
    height: 34.87px;
    position: absolute;
    left: calc(50% - 17px);
    top: calc(50% - 17px);
    filter: blur(20px);
}


.frame-170 {
    display: flex;
    height: 248px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.frame-172-button {
    display: flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--color-text-brand, #F9BC21);
    text-decoration: none;
}

.btn-open-account {
    color: #000;

    /* large */
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 155.556% */
}

@media screen and (max-width: 1440px) {
    .frame-187 {
        padding: 112px 16px;
    }
}

@media screen and (max-width: 768px) {
    .frame-187 {
        padding: 40px 16px;
    }

    .frame-145 {
        flex-direction: column;
        align-items: center;
    }

    .frame-170 {
        height: 80px;
    }

    .frame-170 svg {
        transform: rotate(90deg);
    }

    .card-features {
        flex-direction: row;
    }

    .frame-144-text-1 {
        font-size: 30px;
    }
}
</style>