import Vue from 'vue'
import VueRouter from 'vue-router'
import App from "@/views/App.vue";
import HomePage from "@/views/HomePage.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import TermOfServices from "@/views/TermOfServices.vue";
import GeneralQuestion from "@/views/GeneralQuestion.vue";
import HowToDeposit from "@/views/HowToDeposit.vue";
import HowToStartTrading from "@/views/HowToStartTrading.vue";

Vue.use(VueRouter)

const routes = [

    {
        path: '/',
        name: 'App',
        component: App,
        // meta: {
        //     title: "StakeB"
        // },
        children: [
            {
                path: '/',
                name: 'HomePage',
                component: HomePage,
                meta: {
                    title: "StakeB"
                },
            },
            {
                path: '/privacy-policy',
                name: 'Privacy-Policy',
                component: PrivacyPolicy,
                meta: {
                    title: "Privacy Policy"
                }
            },
            {
                path: '/term-of-services',
                name: 'Term-Of-Service',
                component: TermOfServices,
                meta:{
                    title: "Term Of Service"
                }
            },
            {
                path: '/faq',
                name: 'Faq',
                component: GeneralQuestion,
                meta:{
                    title: "FAQ"
                }
            },
            {
                path: '/how-to-deposit',
                name: 'How-To-Deposit',
                component: HowToDeposit,
                meta:{
                    title: "How To Deposit?"
                }
            },
            {
                path: '/how-to-start-trading',
                name: 'How-To-Start-Trading',
                component: HowToStartTrading,
                meta:{
                    title: "How To Start Trading?"
                }
            }

        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.VUE_APP_BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title.concat(" - Option");
    next();
});

export default router
