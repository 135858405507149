<template>
    <div class="group-17">
        <div class="group-14">
            <div>
                <div class="ellipse-6"></div>
                <Group13 class="group-13" />
            </div>
        </div>
        <div class="group-15">
            <div>
                <div class="ellipse-6"></div>
                <Group13 class="group-13" />
            </div>
        </div>
        <div class="group-16">
            <div>
                <div class="ellipse-6"></div>
                <Group13 class="group-13" />
            </div>
        </div>
    </div>
</template>

<script>
import Group13 from "../assets/svg/group-13.svg";
export default {
    name: "Background",
    components: {
        Group13
    }
}
</script>

<style scoped>
.group-17 {
    position: absolute;
    top: 1200px;
    left: 0;
    right: 0;
    flex-shrink: 0;
}

.group-15 {
    margin-top: 295px;
    display: flex;
    justify-content: right;
}

.group-16 {
    margin-top: 517px;
}

.group-14>div,
.group-15>div,
.group-16>div {
    width: 754px;
    height: 754px;
    flex-shrink: 0;
    position: relative;
}

.group-14,
.group-16 {
    margin-left: -506px;
}

.group-13 {
    top: 197px;
    right: 70px;
    position: absolute;
}

.group-15 .group-13 {
    top: 197px;
    left: 70px;
}

.group-15>div {
    left: 446px;
}

.ellipse-6 {
    width: 754px;
    height: 754px;
    flex-shrink: 0;
    border-radius: 754px;
    opacity: 0.2;
    background: #FAF7B9;
    filter: blur(250px);
}

@media screen and (max-width: 768px) {
    .group-17 {
        display: none;
    }
}
</style>