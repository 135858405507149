<template>
    <div class="general-question">
        <div class="container">
            <div class="frame-title">
                <div class="title">Frequently Ask Question</div>
            </div>

            <div class="frame-content">
                <p></p>
                <div class="title-1">What data is required to register on the stakeB website?</div>
                <div class="wrapper-text">
                    <p>
                        <b>Answer:</b> To earn money on digital options, you must first open an account that allows you
                        to conduct trades. To do this, you need to register on the our website.
                        The registration process is simple and does not take much time.
                        It is necessary to fill out a questionnaire on the proposed form. You will be required to enter
                        the following information:
                    </p>
                    <ul>
                        <li>Name</li>
                        <li>Invite code</li>
                        <li>Email address</li>
                        <li>A password that you will use in future to enter the system (in order to minimize the risk of
                            unauthorized access to your individual account,
                            we recommend that you create a complex password using lowercase, uppercase letters and numbers.
                            Do not disclose the password to third parties)
                        </li>
                    </ul>
                    <p>
                        After filling out the sign-up form, you will be offered various ways to fund your account for
                        trading.
                    </p>
                </div>
                <div class="title-1">How to learn?</div>
                <div class="wrapper-text">
                    <p>
                        <b>Answer:</b> Sign up and train on a demo account. It is exactly the same as real trading, but for
                        free.
                    </p>
                </div>


                <div class="title-1">What is the minimum deposit amount?</div>
                <div class="wrapper-text">
                    <p>
                        <b>Answer:</b> The advantage of the our platform is that you don’t have to deposit large amounts
                        to your account. You can start trading by investing a small amount of money. The minimum deposit is
                        1 US dollars
                    </p>
                </div>
                <div class="title-1">How long does it take to withdraw funds?</div>
                <div class="wrapper-text">
                    <p>
                        <b>Answer:</b> On average, the withdrawal procedure takes from one to five days from the date of
                        receipt of the corresponding request of the Client and depends only on the volume of simultaneously
                        processed
                        requests. The company always tries to make payments directly on the day the request is received
                        from the Client.
                    </p>
                </div>
                <div class="title-1">Is there any fee for depositing of withdrawing funds from the account?</div>
                <div class="wrapper-text">
                    <p>
                        <b>Answer:</b> No. The company does not charge any fee for either the deposit or for the
                        withdrawal
                        operations.
                        However, it is worth considering that payment systems can charge their fee and use the internal
                        currency conversion rate.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GeneralQuestion"
}
</script>

<style scoped>
.general-question {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    max-width: 1200px;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 30px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.frame-title {
    display: flex;
    max-width: 789px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.title {
    color: var(--color-text-primary, #F1F5F9);
    text-align: center;
    font-family: Urbanist;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    align-self: stretch;
}

.effective-date {
    color: var(--color-text-read, #E2E8F0);
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 140% */
}

.frame-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

}

.wrapper-text {
    align-self: stretch;
    color: var(--color-text-read, #E2E8F0);
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.title-1 {
    color: var(--color-text-primary, #F1F5F9);
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 133.333% */
    align-self: stretch;
}

@media screen and (max-width: 1440px) {
    .general-question {
        padding: 0 16px;
        margin-top: 20px;
    }

    .title {
        font-size: 30px;
    }

    .title-1 {
        font-size: 20px;
    }

    .wrapper-text {
        font-size: 16px;
    }
}
</style>