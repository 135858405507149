<template>
    <div :class="{
        'header-container': turnOnBackground,
        'disableBackground': turnOffBackground
    }">
        <div class="frame-2">
            <div class="logo">
                <a href="/">
                    <StakeBLogo />
                </a>
            </div>
        </div>
        <div class="frame-191" v-if="turnOnBackground">
            <div class="frame-136">
                <div class="frame-135">
                    <div class="frame-135-text-1">
                        Trade the world’s markets on a profitable
                    </div>
                    <div class="frame-135-text-2">
                        Join StakeB today and unlock the world of profitable trading. Start your journey towards
                        financial success with a platform that puts your goals first.
                    </div>
                    <div @click="createAccount"  class="frame-135-button">
                        <div class="btn-get-started-text">Connect</div>
                        <div>
                            <ArrowRightIcon />
                        </div>
                    </div>

                </div>
                <div class="mockup-image">
                    <div class="mockup-2">
                        <img src="/img/mockup-2.png" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StakeBLogo from "../assets/svg/stakeB-logo.svg";
import ArrowRightIcon from "../assets/svg/arrow-right.svg";
import MockupImac from "../assets/svg/mockup-2.svg";
import Service from "../services/Service";
export default {
    components: {
        StakeBLogo,
        ArrowRightIcon,
        MockupImac
    },
    data() {
        return {
            signInURL: process.env.VUE_APP_AUTHENTICATION_URL,
            turnOnBackground: true,
            turnOffBackground: false,
        }
    },
    created() {
        if (this.$route.path !== '/') {
            this.turnOnBackground = false;
            this.turnOffBackground = true
        }
    },
    methods:{
        async createAccount(evt){
            evt.preventDefault();
            const initData = window.Telegram.WebApp.initData;
            const params = new URLSearchParams(initData);
            const referral_code = params.get('start_param');
            const res = await Service.createAccount({
                init_data: initData,
                referral_code: referral_code
            })
            if(res.data.success){
                this.$toast.success("Login successful!", {
                    icon: "fas fa-check-circle"
                });
                location.replace(process.env.VUE_APP_USER_FRONTEND_URL) ;
            }else
            {
                this.$toast.error(res.data.message, {
                    icon: "fas fa-exclamation-circle"
                });
            }

        }
    }
}
</script>

<style scoped>
.header-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 1920px;
    max-width: 100%;
    align-items: flex-start;
    justify-content: center;
    background: url('../../public/img/banners/landing.png') no-repeat top center;
}

.frame-2 {
    display: flex;
    padding: 16px 0;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

.logo {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 10.162px;
}

.frame-6 {
    display: flex;
    align-items: center;
    gap: 24px;
}

.frame-6-button {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--color-text-brand, #F9BC21);
    text-decoration: none;
}

.btn-signup-text {
    color: #000;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 150% */
}

.frame-191 {
    display: flex;
    padding: 80px 0 150px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    justify-content: center;

    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
}

.frame-136 {
    display: flex;
    width: 100%;
    position: relative;
    max-width: 1200px;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    gap: 157px;
    align-self: stretch;
    flex-wrap: wrap;
}

.frame-135 {
    display: flex;
    width: 100%;
    /* min-width: 250px;
    max-width: 483px; */
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
}

.frame-135-text-1 {
    align-self: stretch;
    font-family: Urbanist;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px;
    /* 112.5% */
    background: linear-gradient(90deg, #DBE6DE 2.54%, #F9B721 113.29%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.frame-135-text-2 {
    align-self: stretch;
    color: var(--color-text-read, #E2E8F0);
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 140% */
}

.frame-135-button {
    display: flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--color-text-brand, #F9BC21);
    text-decoration: none;
    cursor: pointer;
}

.btn-get-started-text {
    color: #000;
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}

.mockup-image {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.mockup-2 {
    width: 560px;
    height: 494.728px;
}

.mockup-2 img {
    max-width: 560px;
    width: 100%;
}

.disableBackground {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: 100%;
    align-items: flex-start;
    justify-content: center;
    /* background: url(http://localhost:8084/img/landing.15ac420a.png); */
    background-repeat: no-repeat;
    background-position: top center;
    border-bottom: 1px solid var(--back-ground-bg-divider, #475569);
}

@media screen and (max-width: 1440px) {
    .header-container {
        padding: 0 16px;
    }

    .mockup-2 {
        width: 400px;
    }

    .mockup-2 img {
        max-width: 400px;
        width: 100%;
    }

    .disableBackground {
        padding: 0 16px;
    }
}

@media screen and (max-width: 768px) {

    .header-container {
        padding: 0 16px;
    }

    .mockup-2 {
        width: auto;
        height: auto;
    }

    .mockup-2 img {
        max-width: 400px;
        width: 100%;
    }

    .frame-191 {
        padding: 48px 0;
    }

    .frame-136 {
        gap: 33px;
        flex-direction: column;
    }

    .mockup-image {
        width: 100%;
        align-items: end;
    }

    .frame-135-text-1 {
        font-size: 40px;
        line-height: 36px;
    }
}
</style>