<template>
    <div class="frame-156">
        <div class="frame-147">
            <div class="frame-147-logo">
                <StakeBLogoFooter />
                <div class="frame-147-logo-text">@ {{ currentYear }} stakeb.live</div>

            </div>
            <div class="list-navigations">
                <div class="navigation-title">Resources</div>
                <div class="frame-148">
                    <a class="frame-148-wrap-text" :href="privacyPolicy">Privacy Policy</a>
                    <a class="frame-148-wrap-text" :href="termOfService">Term Of Services</a>
                </div>
            </div>
            <div class="list-navigations">
                <div class="navigation-title">FAQ</div>
                <div class="frame-148">
                    <a class="frame-148-wrap-text" :href="faq">General Question</a>
                    <a class="frame-148-wrap-text" :href="howToDeposit">How To Deposit?</a>
                    <a class="frame-148-wrap-text" :href="howToStartTrading">How To Start Trading?</a>
                </div>
            </div>
            <div class="list-navigations">
                <div class="navigation-title">About Us</div>
                <div class="frame-148">
                    <a class="frame-148-wrap-text" :href="aboutUs">About Us</a>
                    <a class="frame-148-wrap-text" :href="contacts">Contacts</a>
                </div>
            </div>

        </div>
        <div class="frame-155">
            <div class="frame-155-text">Community</div>
            <div class="frame-154">
                <TelegramIcon />
                <DiscordIcon />
                <TwitterIcon />
            </div>
        </div>
    </div>
</template>

<script>
import StakeBLogoFooter from '../assets/svg/stakeB-logo-footer.svg'
import TelegramIcon from '../assets/svg/telegram-icon.svg'
import DiscordIcon from '../assets/svg/discord-icon.svg'
import TwitterIcon from '../assets/svg/twiter-icon.svg'

export default {
    name: "Footer",
    data() {
        return {
            currentYear: "",
            privacyPolicy: "/privacy-policy",
            termOfService: "/term-of-services",
            howToDeposit: "/how-to-deposit",
            howToStartTrading: "/how-to-start-trading",
            ourTeam: "#",
            faq: "/faq",
            aboutUs: "/#",
            contacts: "/#"
        }
    },
    methods: {
        getCurrentYear() {
            this.currentYear = new Date().getFullYear().toString();
        }
    },
    created() {
        this.getCurrentYear();
    },
    components: {
        StakeBLogoFooter,
        TelegramIcon,
        DiscordIcon,
        TwitterIcon,
    }
}

</script>

<style scoped>
.frame-156 {
    display: flex;
    padding: 96px 0;
    width: 100%;
    max-width: 1200px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    border-top: 1px solid var(--back-ground-bg-divider, #475569);
}

.frame-147 {
    display: flex;
    min-width: 288px;
    max-width: 1200px;
    align-items: flex-start;
    align-content: flex-start;
    align-self: stretch;
    flex-wrap: wrap;
    position: relative;
}

.frame-147-logo {
    display: flex;
    min-width: 150px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
}

.frame-147-logo-text {
    color: var(--color-text-read, #E2E8F0);
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 155.556% */
}

.list-navigations {
    display: flex;
    min-width: 150px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    position: relative;
}

.navigation-title {
    color: var(--color-text-primary, #F1F5F9);
    /* large */
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 155.556% */
}

.frame-148 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.frame-148-wrap-text {
    color: var(--color-text-secondary, #94A3B8);
    /* list */
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    align-self: stretch;
    text-decoration: none;
}

.frame-155 {
    display: flex;
    align-items: center;
    gap: 16px;
}

.frame-155-text {
    color: #FFF;
    /* lead-18 */
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 155.556% */
}

.frame-154 {
    display: flex;
    align-items: flex-start;
    gap: 16px;
}

.frame-154 {
    cursor: pointer;
}

.frame-154 svg:hover g,
.frame-154 svg:hover path {
    stroke: #F9BC21;
}

@media screen and (max-width: 1440px) {
    .frame-156 {
        padding: 96px 16px;
    }
}

@media screen and (max-width: 600px) {

    .frame-5 {
        flex-direction: column;
        gap: 24px;
    }

    .frame-156 {
        padding: 40px 16px;
    }

    .list-navigations {
        text-align: right;
        align-items: initial;
    }

    .frame-147 .list-navigations:nth-child(4) {
        margin-top: 24px;
    }

}


@media screen and (max-width: 481px) {
    .frame-147 .list-navigations:nth-child(3) {
        margin-top: 24px;
    }
}
</style>