<template>
    <div class="homepage">
        <MakeMoneySection />
        <StakeBFeature />
        <DepositSection />
        <GetStarted />
    </div>
</template>

<script>
import MakeMoneySection from "@/components/MakeMoneySection.vue";
import DepositSection from "@/components/DepositSection.vue";
import StakeBFeature from "@/components/StakeBFeatureSection.vue";
import GetStarted from "@/components/GetStartedSection.vue";

export default {
    name: "HomePage.vue",
    components: { GetStarted, StakeBFeature, DepositSection, MakeMoneySection }
}
</script>

<style scoped>
.homepage {
    max-width: 100%;
    margin: 0 auto;
}
</style>