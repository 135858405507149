<template>
    <div class="how-to-deposit">
        <div class="container">
            <div class="frame-title">
                <div class="title">How To Deposit?</div>
            </div>
            <div class="frame-content">
                <div class="title-1">Prerequisites</div>
                <div class="wrapper-text">
                    <ul>
                        <li> You must have a cryptocurrency wallet such as Metamask, Trust Wallet, Coinbase Wallet, etc...</li>
                    </ul>
                </div>
                <div class="title-1">Step 1: Choose the token you want to deposit </div> 
                <div class="wrapper-text">
                    <ul>
                        <li>Click on the <b>Operation </b> button and select <b>Deposit</b>. A pop-up window will appear with instructions to complete the deposit process.</li>
                    </ul>
                </div>
                <div class="title-1">Step 2: Transfer your token from your wallet to stakeB wallet </div>
                <div class="wrapper-text">
                    <ul>
                        <li>Scan the QR code or copy the address from the deposit popup to send tokens from your wallet.</li>
                        <li>Please enter the number of tokens you wish to transfer to the stakeB wallet.</li>
                    </ul>
                </div>
                <div class="title-1">Step 3: Confirm transaction </div>
                <div class="wrapper-text">
                    <ul>
                        <li>Please ensure that your transaction transfer token is successfully transferred from your wallet to the stakeB wallet.</li>
                        <li>The system requires a few minutes to process your deposit. Click the <b>Refresh</b> button to check your balance.</li>
                        <li>After 30 minutes, if your balance does not increase by the amount of tokens transferred, please contact our support team by creating a ticket or sending an email to <b>stakeb.helpcenter@gmail.com</b>.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HowToDeposit",
}
</script>

<style scoped>
.how-to-deposit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    max-width: 1200px;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 30px;
    font-family: Urbanist;
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.frame-title {
    display: flex;
    max-width: 789px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}
.title {
    color: var(--color-text-primary, #F1F5F9);
    text-align: center;
    font-family: Urbanist;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    align-self: stretch;
}
.frame-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    min-height: 60vh;
}
.wrapper-text {
    align-self: stretch;
    color: var(--color-text-read, #E2E8F0);
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}
.title-1 {
    color: var(--color-text-primary, #F1F5F9);
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 133.333% */
    align-self: stretch;
}


@media screen and (max-width: 1440px) {
    .how-to-deposit {
        padding: 0 16px;
        margin-top: 20px;
    }

    .title {
        font-size: 30px;
    }

    .title-1 {
        font-size: 20px;
    }

    .wrapper-text {
        font-size: 16px;
    }
}
</style>