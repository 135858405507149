<template>
    <div class="congratulation" :class="{ 'show': isShow }">
        <div class="overlay"></div>
        <div class="group-37417">
            <div>
                <img src="/img/group-37412.png" />
                <div
                    style="z-index: 1; flex-direction: column; justify-content: center; align-items: center; gap: 32px; display: inline-flex">
                    <div
                        style="flex-direction: column; justify-content: center; align-items: center; gap: 24px; display: flex">
                        <div
                            style="flex-direction: column; justify-content: flex-start; align-items: center; gap: 48px; display: flex">
                            <div
                                style="flex-direction: column; justify-content: flex-start; align-items: center; gap: 8px; display: flex">
                                <div
                                    style="color: white; font-size: 40px; font-family: Urbanist; font-weight: 700; line-height: 36px; word-wrap: break-word">
                                    Congratulations!!</div>
                                <div
                                    style="max-width: 430.17px; text-align: center; color: #C4D3D9; font-size: 20px; font-family: Lato; font-weight: 400; line-height: 28px; word-wrap: break-word">
                                    Congrats on claiming your reward! Please click the "Claim now!" button to receive it.
                                </div>
                            </div>
                        </div>
                        <div
                            style="color: #DBE6DE; font-size: 64px; font-family: Urbanist; font-weight: 700; line-height: 72px; word-wrap: break-word;
                        background: linear-gradient(90deg, #DBE6DE 2.54%, #F9B721 113.29%);background-clip: text;-webkit-background-clip: text;-webkit-text-fill-color: transparent;">
                            0.0001 BTC</div>
                    </div>
                    <div @click='createAccount'class="claim-button">
                        <div
                            style="color: black; font-size: 20px; font-family: Urbanist; font-weight: 700; line-height: 28px; word-wrap: break-word">
                            Claim now!
                        </div>
                    </div >
                    <div style="width: 40px; height: 40px; position: relative;cursor: pointer;" @click="closePrize">
                        <CloseIcon />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CloseIcon from "../assets/svg/close.svg";
import Service from "@/services/Service";
export default {
    props: {
        closePrize: Function,
        isShow: Boolean
    },
    name: "Congratulation",
    components: {
        CloseIcon
    },
    data() {
        return {
            signInURL: process.env.VUE_APP_AUTHENTICATION_URL,
        }
    },
    methods:{
        async createAccount(evt){
            evt.preventDefault();
            const initData = window.Telegram.WebApp.initData;
            const params = new URLSearchParams(initData);
            const referral_code = params.get('start_param');

            const res = await Service.createAccount({
                init_data: initData,
                referral_code: referral_code
            })
            if(res.data.success){
                this.$toast.success("Login successful!", {
                    icon: "fas fa-check-circle"
                });
                location.replace(process.env.VUE_APP_USER_FRONTEND_URL) ;
            }else
            {
                this.$toast.error(res.data.message, {
                    icon: "fas fa-exclamation-circle"
                });
            }

        }
    }
}
</script>

<style scoped>
.congratulation {
    z-index: 9999;
    opacity: 0;
    position: fixed;
    transition: opacity 1s;
    z-index: 0;
    display: none;
}

.congratulation.show {
    opacity: 1;
    z-index: 2;
    display: initial;
}

.group-37417 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.8;
    background: #000;
}

.group-37417>div {
    width: 691px;
    height: 691px;
    position: relative;
    display: flex;
    justify-content: center;
}

.group-37417>div>img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 691px;
    height: 691px;
}

.claim-button {
    width: 157px;
    padding: 16px;
    background: #F9BC21;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
    cursor: pointer;
    text-decoration: none;
    cursor: pointer;
}
</style>